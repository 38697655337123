export default class NgFileDirective {
  constructor($parse) {
    this.restrict = "A"; // restrict the directive to be used as an attribute
    this.$parse = $parse;
  }

  link(scope, element, attrs) {
    const model = this.$parse(attrs.ngFileDirective);

    element.on("change", () => {
      const propertyPath = attrs.ngFileDirective.split(".");
      let currentScopeModel = scope;

      let processedDepth = 0;
      let pathDepth = 0;
      for (const property of propertyPath) {
        pathDepth++;
        if (
          typeof currentScopeModel[property] !== "undefined" &&
          (propertyPath.length === 0 || propertyPath.length !== pathDepth)
        ) {
          currentScopeModel = currentScopeModel[property];
          processedDepth++;
        }
      }

      currentScopeModel[propertyPath[propertyPath.length - 1]] = element[0].files;
    });
  }

  static factory($parse) {
    return new NgFileDirective($parse);
  }
}

// Dependency Injection
NgFileDirective.factory.$inject = ["$parse"];
