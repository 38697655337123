import clientSpecificHeaderMenuDirective from "./clientSpecificHeaderMenu.directive";
import customPaginationDirective from "./customPagination.directive";
import excelFileReader from "./excelFileReader.directive";
import expandCollapseFeatureDirective from "./expandCollapseFeature.directive";
import fileModelDirective from "./fileModel.directive";
import fileModelMultiDirective from "./fileModelMulti.directive";
import highlightNavItem from "./highlightNavItem.directive";
import highlightParentNavItem from "./highlightParentNavItem.directive";
import isCurrency from "./isCurrency.directive";
import isPercentage from "./isPercentage.directive";
import ngFileDirective from "./ngFile.directive";
import ngFileModelDirective from "./ngFileModel.directive";
import ngRequired from "./ngRequired.directive";
import numberTextColor from "./numberTextColor.directive";
import numericOnly from "./numericOnly.directive";

export const appDirectives = {
  clientSpecificHeaderMenuDirective,
  customPaginationDirective,
  excelFileReader,
  expandCollapseFeatureDirective,
  fileModelDirective,
  fileModelMultiDirective,
  highlightNavItem,
  highlightParentNavItem,
  isCurrency,
  isPercentage,
  ngFileModelDirective,
  ngRequired,
  numberTextColor,
  numericOnly,
  ngFileDirective,
};

export default appDirectives;
